import React from "react";
import { Routes, Route } from "react-router-dom";

import Donation from '../components/donation/Donation';
import Recurring from '../components/recurring/Recurring';

const Routeing = () => {

    return (
        <Routes>
            <Route path="/" element={<Donation />}/>
            <Route path="/recurring" element={<Recurring />}/>
            <Route path='/unsubscribe' element={<Recurring />}/>
            <Route path='/payment/confirmation' element={<Donation />}/>
        </Routes>
    )
}

export default Routeing;