import React from "react";
import PropTypes from "prop-types";
import "./modal.scss";
import x from '../../assets/icons/icon-x.svg';
import failAM from '../../assets/icons/failAM.png';
import failEN from '../../assets/icons/failEN.png';
import allow from '../../assets/icons/allow.png';


const Modal = ({showModal, title, toggleModal, modalWidth, modalHeight, modalType, children, message = ''}) => (
    showModal ?
        <>
            <div className={`modal-overlay gray-background ${modalType !== 'policy' && 'modal-overlay-owl'}`} onClick={toggleModal}></div>
            <div className={`modal-parent-center modal-parent-center-mobile ${modalType !== 'policy' && 'owl'}`}
                 style={{maxWidth : modalWidth, height : modalHeight}}>
                <div className={`close-modal-box ${modalType !== 'policy' && 'modal-parent-center-logo'}`}>
                    <img className="close-modal" src={x} alt="x" onClick={toggleModal}/>
                </div>
                {!children && modalType !== 'policy' && <img className="owl" src={modalType === 'failedAM' ? failAM : modalType === 'failedEN' ? failEN : allow} alt='failAm'/>}
                {message ? <span className='modal-title' >{message} </span>  : null}
                {!children && title ? <span className='modal-title'>{title}</span> : ''}
                {children}
            </div>
        </>
        : null
);

Modal.propTypes = {
    children    : PropTypes.element,
    title       : PropTypes.string,
    toggleModal : PropTypes.func.isRequired,
    showModal   : PropTypes.bool,
    modalWidth  : PropTypes.string,
    modalType   : PropTypes.string
};
export default Modal;
