import React from "react";
import PropTypes from 'prop-types';
import './button.scss';

const Button = ({action, children, styles, disabled, type}) => {
    return (
        <button className={styles} onClick={action} disabled={disabled} type={type}>{children}</button>
    )
}

Button.propTypes = {
    action: PropTypes.func,
    children: PropTypes.string,
    disabled: PropTypes.bool,
    styles: PropTypes.string,
    type: PropTypes.string,
}

export default Button;