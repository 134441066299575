import React from 'react';
import './App.css';
import Header from './components/header/Header';
import Routeing from "./routes/Routes";
import Footer from './components/footer/Footer';
import './assets/fonts/mardoto/Mardoto-Regular.ttf';
import './assets/fonts/bokonique/Bokonique-Regular.otf';
import './assets/fonts/montserratArm/Montserratarm-Regular.otf';

function App() {
    return (
        <div className="App">
            <>
                <Header />
                <Routeing />
                <Footer />
            </>
        </div>
    );
}

export default App;
