import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
    en : {
        translation : {
            "plan_created": "Plan created",
            "currency" : "Dram",
            home: "HOME",
            news: "NEWS",
            community: "COMMUNITY",
            demand: "DEMAND",

            mainTitle: "Support the Gituzh Initiative",
            mainSubtitle: "Donate to empower science development & science popularization in Armenia.",
            
            monthlyDonation: "Monthly Donation",
            other: "Other",
            
            amount: "Amount ",
            name: "Name ",
            lastName: "Last name ",
            email1: "Email ",
            notes: "Notes ",
            conditions: "I have read and agree to the terms and conditions.",
            anonymousDonation: "I want to make an anonymous donation.",
            donate: "Donate",
            donationTermsConditions: "Donation system terms and conditions of use",
            
            generalProvisions: "General Provisions",
            generalProvisionsPar1: "By using the www.gituzh.am website (hereinafter the Website) donation system, you (hereinafter the Visitor) agree to the terms and conditions as well as privacy policy outlined below. Please read the text carefully.",
            generalProvisionsPar2: "Please note that we reserve the right to make changes to the Website content, Privacy Policy, and Terms and  Conditions from time to time.",
            
            dataStorageCollection: "Collection and Storage of Personal Data",
            dataStorageCollectionTitle1: "The personal information permitted to be stored under these Privacy Conditions is provided by the Visitor and may include the following information:",
            nameSurname: "Name, surname ",
            organizationName : "Organization name",
            phoneNumber: "Phone number",
            eMail: "E-mail address",
            bankInformation: "Bank information",
            etc: "Etc.",
            dataStorageCollectionTitle2: "User's personal data is collected to:",
            dataStorageCollectionLi1: "To accept your donations and support your subscription,",
            dataStorageCollectionLi2: "To identify the data of the Visitor to the Website, to confirm the accuracy and completeness of the information,",
            dataStorageCollectionLi3: "To provide feedback and information to the Visitor by sending emails related to the donation,",
            dataStorageCollectionLi4: "To provide effective technical support to the Visitor in case of problems during or after making a donation.",
            dataStorageCollectionPar1: "The terms and conditions, including privacy policy apply to all the information that our website may receive about you while visiting the Website.",
            dataStorageCollectionPar2: "By accepting these terms and conditions, you confirm that the information provided at the time of or after making a donation is accurate and complete.",
            dataStorageCollectionPar3: "Your personal data is stored in secure databases and cannot be shared with non-affiliated third parties.",
            
            donationIssues: "Donation Issues",
            donationIssuesText: "In case of any problem or questions related to the donation, you can contact us at the following email address info@gituzh.am or by phone at +374 (94) 87 83 00.",
            
            websiteLiability: "Website liability",
            websiteLiabilityText1: 'The owner of the website is the "Gituzh" Foundation (hereinafter "the Foundation"), registered in the Republic of Armenia (Tax code: 00248311), and founded as a support association for the activities of the "Gituzh" initiative.',
            websiteLiabilityText2: 'Your donation made on the website is accepted if you receive a confirmation email with all the details about the donation. That email is considered an agreement between you and the Website, and from that moment the donation is considered complete. These terms and conditions are an integral part of that agreement. With the act of donation, the amount you donated is henceforth under the disposal of the "Gituzh" Foundation.',
            websiteLiabilityText3: "The Website contains links to other online resources. If you follow a link to any of these resources, you acknowledge and agree that the Foundation is not responsible for the “User Content” provided by that link and the inclusion of any link on the Website does not imply our affiliation, endorsement, or adoption of the linked website or other content or any information therein.",
            
            cancelationDonations: "Cancelation of Recurring Donations",
            cancelationDonationsText: "Recurring donations can be canceled at any time. If you wish to cancel your recurring donation, you can follow the link we have provided via email or contact us at info@gituzh.am. To avoid future charges you may cancel your recurring donation prior to the next donation date.",
            
            lawDisputeResolution: "Applicable Law & Dispute Resolution",
            lawDisputeResolutionPar1: "The legislation of the Republic of Armenia shall apply to the relations between the Foundation and the Supporter arising from the use of the Website on the basis of these Terms and Conditions.",
            lawDisputeResolutionPar2: "The disputes arising between the Foundation and the Supporter shall be resolved through negotiations. For this purpose, the Supporters may submit their complaints/requests to the Foundation by sending an e-mail to info@gituzh.am, indicating the grounds for the complaint/request.",
            lawDisputeResolutionPar3: "The Foundation undertakes to respond to the email/s sent by the Supporter within 10 (ten) working days. If the Parties fail to settle their dispute through negotiations within 15 (fifteen) working days, the dispute shall be subject to review by the courts of the Republic of Armenia.",
            lawDisputeResolutionPar4: 'The Terms & conditions (as defined above) for the Website represent a legally binding agreement between you, an individual supporter (the "Supporter"), and the Foundation regarding your use of the system to make a donation.',
            
            oneTimeDonation: "One-time Donation",
            oneTimeDonationRule1: "For a one-time donation, please use the following credentials.",
            oneTimeDonationRule2: "Please provide the intermediary bank's credentials to facilitate the transaction.",
            
            accounts: "Accounts",

            contactUs: "Contact us",
            email2: "Email:",
            phoneNumer: "Tel:",
            rightsReserved: "GitUzh | All Rights Reserved",
            understandableScience: "Making science understandable and",
            accessiblePublic: "accessible to the public.",
            followSocialMedia: "Follow us on social media.",

            recurringDonation1: "Recurring Donation: ",
            recurringDonation2: "Recurring Donation",
            active: "Active",
            inactive: "Inactive",
            recurringId: "Recurring ID",
            nextTransaction: "Next transaction",
            campaign: "Campaign",
            gituzhFoundation: "The Gituzh Foundation",
            cancelDonation: "Cancel Donation",
            cancelRequrring: "Are you sure you want to cancel the recurring donation?",
            yes: "Yes",
            no: "No",
            petros: "Petros",
            petrosyan: "Petrosyan",
            owlSuccess: "You have successfully completed the subscription process."
        }
    },
    am : {
        translation : {
            currency: "Դրամ",
            "plan_created" :"Ստեղծվել է",
            home: "ԳԼԽԱՎՈՐ",
            news: "ԼՈՒՐԵՐ",
            community: "ՀԱՄԱՅՆՔ",
            demand: "ՊԱՀԱՆՋ",

            mainTitle: "Աջակցի՛ր Գիտուժ նախաձեռնությանը",
            mainSubtitle: "Նվիրաբերելով Գիտուժին՝ դու աջակցում ես Հայաստանում գիտության զարգացմանը և հանրայնացմանը։",
            
            monthlyDonation: "Ամսական նվիրաբերություն",
            other: "Այլ",

            amount: "Գումար ",
            name : "Անուն ",
            lastName: "Ազգանուն ",
            email1: "Էլ. հասցե ",
            notes: "Նշումներ ",
            conditions: "Ես կարդացել և համաձայն եմ դրույթներին և պայմաններին։",
            anonymousDonation: "Գերադասում եմ մնալ անանուն։",
            donate: "Նվիրաբերել",
            donationTermsConditions: "Նվիրաբերության համակարգի օգտագործման դրույթներ եւ  պայմաններ",
            
            generalProvisions: "Ընդհանուր դրույթներ եւ պայմաններ",
            generalProvisionsPar1: "Օգտվելով www.gituzh.am կայքի (այսուհետ՝ Կայքը) նվիրաբերության համակարգից՝ Դուք (այսուհետ՝ Նվիրաբերողը)  Ձեր համաձայնությունն եք տալիս ստորեւ շարադրված դրույթներին եւ պայմաններին, այդ թվում նաեւ գաղտնիության քաղաքականությանը: Խնդրում ենք ուշադիր կարդալ տեքստը:",
            generalProvisionsPar2: "Խնդրում ենք հաշվի առնել, որ մենք իրավունք ենք վերապահում Կայքի բովանդակության, «Գաղտնիության քաղաքականության» մեջ եւ սույն «դրույթներ եւ պայմաններ»-ում ժամանակ առ ժամանակ փոփոխություններ կատարել։",
            
            dataStorageCollection: "Անձնական տվյալների հավաքագրում եւ պահպանում",
            dataStorageCollectionTitle1: "Անձնական տվյալները, որոնք թույլատրվում է պահպանել սույն Գաղտնիության պայմանների շրջանակներում, տրամադրվում են Նվիրաբերողի կողմից եւ կարող են ներառել հետեւյալ տեղեկությունները.",
            nameSurname: "անունը, ազգանուն,",
            organizationName: "կազմակերպության անուն,",
            phoneNumber: "հեռախոսի համար,",
            eMail: "էլեկտրոնային փոստի հասցե (e-mail),",
            bankInformation: "բանկային տվյալներ,",
            etc: "եւ այլն։",
            dataStorageCollectionTitle2: "Օգտագործողի անձնական տվյալները հավաքագրվում են.",
            dataStorageCollectionLi1: "Ձեր կողմից կատարված նվիրաբերություններն ընդունելու եւ սպասարկելու համար,",
            dataStorageCollectionLi2: "Կայքի Այցելուի տվյալների նույնականացման, տեղեկատվության ճշգրտությունն ու ամբողջականությունը հաստատելու համար,",
            dataStorageCollectionLi3: "Այցելուի հետ հետադարձ կապի ապահովման՝ նվիրաբերության հետ կապված ծանուցումներ ուղարկելու համար,",
            dataStorageCollectionLi4: "Նվիրաբերություն կատարելու ընթացքում կամ հետո խնդիրների դեպքում Այցելուին արդյունավետ տեխնիկական աջակցություն  ցուցաբերելու համար։",
            dataStorageCollectionPar1: "Սույն գաղտնիության պայմանները տարածվում են բոլոր այն տեղեկությունների վրա, որոնք մեր Կայքը կարող է ստանալ Ձեր մասին՝ Կայքից օգտվելու ժամանակ:",
            dataStorageCollectionPar2: "Ընդունելով այս դրույթները եւ պայմանները՝ Դուք հավաստում եք, որ նվիրաբերության ժամանակ կամ դրանից հետո տրամադրված տվյալները ճշգրիտ եւ ամբողջական են:",
            dataStorageCollectionPar3: "Ձեր անձնական տվյալները պահպանվում են ապահով վայրում եւ չեն կարող տարածվել կամ փոխանցվել մեզ հետ չառնչվող երրորդ կողմերին։",
            
            donationIssues: "Նվիրաբերության հետ կապված խնդիրներ",
            donationIssuesText: "Նվիրաբերության հետ կապված որեւէ խնդրի կամ հարցի դեպքում կարող եք կապ հաստատել մեզ հետ հետեւյալ էլ. փոստի հասցեով՝  info@gituzh.am կամ +374 (94) 87 83 00 հեռախոսահամարով:",
            
            websiteLiability: "Կայքի պատասխանատվությունը",
            websiteLiabilityText1: "Կայքի սեփականատեր է հանդիսանում Հայաստանի Հանրապետությունում գրանցված (ՀՎՀՀ 00248311) «Գիտուժ» հիմնադրամը (այսուհետ` Հիմնադրամ), որը ստեղծվել է որպես «Գիտուժ» նախաձեռնության գործունեությանն աջակցող կառույց։",
            websiteLiabilityText2: "Կայքով կատարված Ձեր նվիրաբերությունը համարվում է ընդունված, եթե Ձեր էլ. փոստին մեր կողմից ուղարկվում է նվիրաբերությունը հաստատող նամակ՝ նվիրաբերության մասին մանրամասներով: Այդ նամակը համարվում է Կայքի եւ Ձեր միջեւ կնքված պայմանագիր, եւ այդ պահից նվիրաբերությունը համարվում է կատարված։ Սույն դրույթները եւ պայմանները համարվում են այդ պայմանագրի անբաժանելի մասը:  Նվիրաբերության կատարումից սկսած՝ Ձեր նվիրաբերած գումարն անցնում է «Գիտուժ» հիմնադրամի տնօրինության տակ:",
            websiteLiabilityText3: "Կայքը պարունակում է այլ առցանց ռեսուրսների հղումներ: Եթե դուք հետեւում եք այդ ռեսուրսներից որեւէ մեկի հղմանը, դուք տալիս եք Ձեր համաձայնությունը, որ Հիմնադրամը պատասխանատվություն չի կրում այդ հղումով տրամադրված «Օգտատիրոջ բովանդակության» համար, եւ Կայքում որեւէ հղումի ներառումը չի ենթադրում մեր կապը, հաստատումը կամ ընդունումը հղված կայքի կամ դրանում առկա այլ բովանդակության կամ տեղեկատվության վերաբերյալ:",
            
            cancelationDonations: "Պարբերական նվիրատվությունների չեղարկում",
            cancelationDonationsText: "Պարբերական նվիրատվությունները կարող են ցանկացած պահի չեղարկվել: Եթե ցանկանում եք չեղարկել Ձեր պարբերական նվիրատվությունը, այցելեք մեր կողմից նամակով Ձեզ ուղարկած հղմամբ կամ կապ հաստատեք մեզ հետ՝ info@gituzh.am էլ. հասցեով։ Ապագա գանձումներից խուսափելու համար կարող եք Ձեր պարբերական նվիրատվությունը չեղարկել մինչեւ հաջորդ նվիրատվության օրը:",
            
            lawDisputeResolution: "Կիրառվող օրենք եւ վեճերի լուծում",
            lawDisputeResolutionPar1: "Հայաստանի Հանրապետության օրենսդրությունը կիրառվում է սույն Դրույթների եւ պայմանների հիման վրա Կայքի օգտագործումից բխող Հիմնադրամի եւ Նվիրաբերողի հարաբերությունների նկատմամբ:",
            lawDisputeResolutionPar2: "Հիմնադրամի եւ Նվիրաբերողի միջեւ ծագած վեճերը լուծվում են բանակցությունների միջոցով: Այդ նպատակով Նվիրաբերողը կարող է իր բողոքները/խնդրանքները ներկայացնել Հիմնադրամին՝ info@gituzh.am էլ. հասցեով։",
            lawDisputeResolutionPar3:"Հիմնադրամը պարտավորվում է պատասխանել Նվիրաբերողի ստացած նամակին 10 (տասը) աշխատանքային օրվա ընթացքում: Եթե Կողմերը 15 (տասնհինգ) աշխատանքային օրվա ընթացքում չեն կարողանում իրենց վեճը լուծել բանակցությունների միջոցով, ապա վեճը ենթակա է քննության Հայաստանի Հանրապետության դատարանի կողմից:",
            lawDisputeResolutionPar4: "Կայքի դրույթները եւ պայմանները (ինչպես սահմանված է վերեւում) համարվում է Ձեր՝ անհատ Նվիրաբերողի («Նվիրաբերող»), եւ Հիմնադրամի միջեւ իրավականորեն պարտադիր համաձայնագիր՝ նվիրաբերություն կատարելու համար համակարգն օգտագործելու վերաբերյալ:",
            
            oneTimeDonation: "Միանվագ նվիրաբերություն",
            oneTimeDonationRule1: "Եթե ​​ցանկանում եք կատարել միանվագ նվիրաբերություն, խնդրում ենք օգտագործել հետևյալ հաշվեհամարները և ռեկվիզիտները:",
            oneTimeDonationRule2: "Գումար փոխանցելիս խնդրում ենք չմոռանալ տրամադրել միջնորդ բանկի ռեկվիզիտները՝ գործարքը բարեհաջող իրականացնելու համար:",
            
            accounts: "Հաշվեհամարներ",

            contactUs: "Կապ",
            email2: "Էլ.Հասցե:",
            phoneNumer: "Հեռ։",
            rightsReserved: "Գիտուժ | Բոլոր իրավունքները պաշտպանված են։",
            understandableScience: "Դարձնել գիտությունը հասկանալի և",
            accessiblePublic: "հասանելի հանրության համար։",
            followSocialMedia: "Հետևի՛ր մեզ սոցիալական ցանցերում։",
            
            recurringDonation1: "Պարբերական նվիրաբերություն` ",
            recurringDonation2: "Պարբերական նվիրաբերություն",
            active: "Ակտիվ",
            inactive: "Ոչ ակտիվ",
            recurringId: "Փոխանցման համար",
            nextTransaction: "Հաջորդ գործարքը",
            campaign: "Կազմակերպություն",
            gituzhFoundation: "<<Գիտուժ>> նախաձեռնություն",
            cancelDonation: "Չեղարկել Նվիրատվությունը",
            cancelRequrring: "Համոզվա՞ծ եք, որ ցանկանում եք չեղարկել պարբերական նվիրաբերությունը։",
            yes: "Այո",
            no: "Ոչ",
            petros: "Պետրոս",
            petrosyan: "Պետրոսյան",
            owlSuccess: 'Շնորհակալությու՜ն։ Դուք հաջողությամբ բաժանորդագրվել եք «Գիտուժ» նախաձեռնության։'

        }
    }
};

const DETECTION_OPTIONS = {
    order: ['localStorage', 'navigator'],
    caches: ['localStorage']
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        interpolation : {
            escapeValue : false, // not needed for react as it escapes by default
        },
        react: {
            wait: true,
        },
        detection: DETECTION_OPTIONS,
        lng: localStorage.getItem("i18nextLng") || "en",
        fallbackLng: localStorage.getItem("i18nextLng") || "en",
    });

export default i18n;