import React from "react";
import './header.scss';
import logo from '../../assets/icons/logo.svg';
import {useTranslation} from "react-i18next";

const Header = () => {
    const {i18n} = useTranslation();
    const currentLang = i18n.language;

    const engBtn =
        <button className={`btn ${currentLang === 'en' ? 'active' : ""}`} onClick={() => i18n.changeLanguage('en')}>
            ENG
        </button>

    const armBtn =
        <button className={`btn ${currentLang === 'am' ? 'active' : ""}`} onClick={() => i18n.changeLanguage('am')}>
            ՀԱՅ
        </button>

    return (
        <div className="header">
            <div className="logoDiv">
                <a href={`http://gituzh.am/${currentLang === 'en' ? "en" : ''}`} aria-current="page">
                    <img src={logo} alt="logo" name="logo"/>
                </a>
            </div>

            <div className='menuDiv'>
                {engBtn}
                |
                {armBtn}
            </div>
        </div>
    )
}

export default Header;