import React, {useEffect, useState} from "react";
import {useTranslation} from 'react-i18next';
import './recurring.scss';
import Modal from "../modal/Modal";
import Button from "../button/Button";
import {useLocation, useSearchParams} from "react-router-dom";
import {addMonths, format} from "date-fns";

const Recurring = () => {
    const { t} = useTranslation();
    const [searchParams] = useSearchParams();
    const {state : routerState} = useLocation();
    const subscriptionPaymentId = searchParams.get('id');
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showResponseModal, setShowResponseModal] = useState(!subscriptionPaymentId);
    const [subscriptionsStatus, setSubscriptionsStatus] = useState('');

    const [state, setState] = useState(routerState);

    useEffect(() => {
        if(subscriptionPaymentId) {
            fetch(`https://gituzh-donations.herokuapp.com/subscription/active?pageId=${subscriptionPaymentId}`, {
                method  : 'GET',
                headers : {
                    'Content-Type' : 'application/json'
                }
            }).then(response => response.json())
                .then(response => setState(response))
        }
    }, [])

    const status = state?.status === "Active";

    const cancelDonation = () => {
        if(state?.subscriptionPageId) {
            fetch(`https://gituzh-donations.herokuapp.com/subscription/${state?.subscriptionPageId}/cancel`, {
                method  : 'PUT',
                headers : {
                    'Content-Type' : 'application/json'
                },
            })
                .then(response => response.json())
                .then(response => {
                    setSubscriptionsStatus(response?.status)
                    setShowConfirmModal(false);
                })
                .catch(() => {
                    setShowConfirmModal(false);
                })
        }
    }

    debugger;
    return (
        <div className="recurringPage">
            <div className="recurringDonation">
                <div className="recurringHead">
                    <span className="recurringTitle">{t('recurringDonation1')}</span>
                    <span className="grayTitles">
                        {t('recurringDonation2')}
                        <span className="recurringStatus">
                            {subscriptionsStatus !== 'Canceled' ?
                                status ?
                                    t('active')
                                    :
                                    t('inactive')
                                :
                                t('inactive')
                            }
                        </span>
                    </span>
                </div>

                <div className="recurringInfo">
                    <div className="infoBox">
                        <span className="grayTitles">{t('amount')}</span>
                        <span className="info">{state?.amount ? `${state?.amount} ${t('currency')}` : "N/A"}</span>
                    </div>
                    <div className="infoBox">
                        <span className="grayTitles">Plan created</span>
                        <span className="info">{state?.createdAt ? format(new Date(state.createdAt), "MM.dd.yyyy") : "N/A"}</span>
                    </div>
                    <div className="infoBox">
                        <span className="grayTitles">{t('nextTransaction')}</span>
                        <span className="info">{state?.updatedAt ? format(addMonths(new Date(state.updatedAt), 1), "MM.dd.yyyy") : "N/A"}</span>
                    </div>
                    <div className="infoBox">
                        <span className="grayTitles">{t('campaign')}</span>
                        <span className="info">{t('gituzhFoundation')}</span>
                    </div>
                </div>

                <div className="cancelDonation">
                    {state?.subscriptionPageId && subscriptionsStatus !== 'Canceled' ? <Button styles={'cancelButton'} type={'submit'} action={() => setShowConfirmModal(true)}>{t('cancelDonation')}</Button> : null}
                </div>
            </div>
            <Modal showModal={showResponseModal}
                   toggleModal={() => setShowResponseModal(false)}
                   modalWidth='600px'
                   modalHeight='450px'
                   modalType={'owl'}
                   title={t('owlSuccess')}>
            </Modal>
            <Modal showModal={showConfirmModal}
                   toggleModal={() => setShowConfirmModal(false)}
                   modalWidth='500px'
                   modalHeight='126px'>
                <p>{t('cancelRequrring')}</p>
                <div className="buttonsDiv">
                    <Button styles={'cancelButton'} action={() => setShowConfirmModal(false)}>{t('no')}</Button>
                    <Button styles={'submitButton'} action={cancelDonation}>{t('yes')}</Button>
                </div>
            </Modal>
        </div>
    )
}

export default Recurring;