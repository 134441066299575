import React, {useEffect, useState} from "react";
import "./donation.scss";
import Modal from "../modal/Modal";
import Button from "../button/Button";
import cardsSvg from "../../assets/icons/cards.png";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {useNavigate, useSearchParams} from "react-router-dom";
import ReactLoading from "react-loading";

const Donation = () => {
    const {i18n} = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const code = searchParams.get("resposneCode");
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isIncognito, setIsIncognito] = useState(false);
    const [showResponseModal, setShowResponseModal] = useState(false);
    const [modalMsgContent, setModalMsgContent] = useState('');
    const {
        register, handleSubmit, formState : {errors}, reset, resetField,
    } = useForm({
        defaultValues : {
            coast : "20000", name : "", surname : "", notes : "",
        },
    });

    useEffect(() => {
        if(isLoading) {
            document.body.style.overflow = "hidden";
            window.scrollTo(0, 0);
        } else {
            document.body.style.overflow = "auto";
        }
    }, [isLoading]);

    useEffect(() => {
        reset({
            name : "", surname : "", email : "", notes : "",
        });
        otherCoast(false);
        const lng = searchParams.get('lng');
        if(lng) {
            i18n.changeLanguage(lng);
            searchParams.delete("lng");
            setSearchParams(searchParams);
        }
    }, []);

    const createSubscription = () => {
        const paymentID = searchParams.get("paymentID");

        fetch("https://gituzh-donations.herokuapp.com/subscription", {
            method  : "POST", headers : {
                "Content-Type" : "application/json",
            }, body : JSON.stringify({
                firstName : searchParams.get("firstName"),
                lastName  : searchParams.get("lastName"),
                email     : searchParams.get("email"),
                amount    : Number(searchParams.get("amount")),
                notes     : searchParams.get("notes"),
                paymentId : paymentID,
                language : i18n.language === 'am' ? "ARM" : "ENG"
            }),
        })
            .then((res) => res.json())
            .then((resp) => {
                if(resp.error) {
                    setIsLoading(false);
                    setShowResponseModal(true);
                }
                if(resp.lastTransferStatus === "Successful") {
                    navigate("/recurring", {state : {...resp}});
                }
            });
    };

    useEffect(() => {
        if(code === "00") {
            debugger
            setIsLoading(true);
            createSubscription();
        } else if(code && code.length) {
            setShowResponseModal(true);
        }
    }, [code]);

    const onSubmit = (e) => {
        const body = {
            email : e.email, amount : Number(e.coast), notes : e.notes,
            language : i18n.language === 'am' ? "ARM" : "ENG"
        };

        if(!isIncognito) {
            body["firstName"] = e.name;
            body["lastName"] = e.surname;
        }

        setIsLoading(true);

        fetch("https://gituzh-donations.herokuapp.com/transaction", {
            method  : "POST", headers : {
                "Content-Type" : "application/json",
            }, body : JSON.stringify(body),
        })
            .then((res) => res.json())
            .then((res) => {
                if(res.paymentId) {
                    window.location.href = `https://services.ameriabank.am/VPOS/Payments/Pay?id=${res["paymentId"]}&amp;lang=${i18n.language}`;
                } else {
                    setShowResponseModal(true);
                    setIsLoading(false);
                    if(res.message && res.error) {
                        setModalMsgContent(res.message)
                    }
                }
            })
            .catch((err) => {
                setIsLoading(false);
                setShowResponseModal(true);
            });
    };

    const handleProfileDropdown = () => {
        setShowModal(!showModal);
    };

    const [showInput, setShowInput] = useState(false);
    const otherCoast = (bin) => {
        setShowInput(bin);
    };

    const {t} = useTranslation();

    const toggleIsLoading = () => {
        setIsIncognito((current) => !current);
        resetField("name");
        resetField("surname");
    };

    return (<div className="donationPage">
        <div className="desc">
            <span className="title">{t("mainTitle")}</span>
            <span className="description">{t("mainSubtitle")}</span>
        </div>

        {isLoading ? (<div className="loadingBackground">
            <ReactLoading type={"spokes"} color="#fff"/>
        </div>) : ("")}
        <div className="donationDiv">
            <div className="monthlyDonation">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <span className="monthlyTitle">{t("monthlyDonation")}</span>
                    <div className="donationOptions">
                        <label className="coast" htmlFor="r20">
                            <input
                                id="r20"
                                type="radio"
                                name="radio"
                                value={20000}
                                {...register("coast")}
                                onChange={() => otherCoast(false)}
                            />
                            <span className="radioText">20,000</span>
                            <span className="checkmark"></span>
                        </label>
                        <label className="coast" htmlFor="r50">
                            <input
                                id="r50"
                                type="radio"
                                name="radio"
                                value={50000}
                                {...register("coast")}
                                onChange={() => otherCoast(false)}
                            />
                            <span className="radioText">50,000</span>
                            <span className="checkmark"></span>
                        </label>
                        <label className="coast" htmlFor="r250">
                            <input
                                id="r250"
                                type="radio"
                                name="radio"
                                value={250000}
                                {...register("coast")}
                                onChange={() => otherCoast(false)}
                            />
                            <span className="radioText">250,000</span>
                            <span className="checkmark"></span>
                        </label>
                        <label className="coast" htmlFor="rCustom">
                            <input
                                id="rCustom"
                                type="radio"
                                name="radio"
                                {...register("coast")}
                                checked={showInput}
                                onClick={() => otherCoast(true)}
                            />
                            <span className="radioText">{t("other")}</span>
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    {showInput && (<label>
                <span className="labelText">
                  {t("amount")}
                    <span className="color-red">*</span>
                </span>
                        <input
                            {...register("coast", {
                                required : true, pattern : /^-?[0-9]\d*\.?\d*$/,
                            })}
                            type="text"
                            placeholder="10,000"
                            className={errors?.coast && "red-border"}
                        />
                    </label>)}
                    <label>
              <span className="labelText">
                {t("name")}
                  {!isIncognito && <span className="color-red">*</span>}
              </span>
                        <input
                            disabled={isIncognito}
                            className={!isIncognito ? errors?.name && "red-border" : ""}
                            {...register("name", {required : !isIncognito, minLength : 3})}
                            type="text"
                            placeholder={t("petros")}
                        />
                        {/* pattern : /^[A-Za-z]+$/ */}
                    </label>
                    <label>
              <span className="labelText">
                {t("lastName")}
                  {!isIncognito && <span className="color-red">*</span>}
              </span>
                        <input
                            disabled={isIncognito}
                            className={!isIncognito ? errors?.surname && "red-border" : ""}
                            {...register("surname", {
                                required : !isIncognito, minLength : 3,
                            })}
                            type="text"
                            placeholder={t("petrosyan")}
                        />
                        {/* pattern : /^[A-Za-z\D]*[A-Za-z\S]$/ */}
                    </label>
                    <label>
              <span className="labelText">
                {t("email1")}
                  <span className="color-red">*</span>
              </span>
                        <input
                            type="text"
                            placeholder=""
                            className={errors?.email && "red-border"}
                            {...register("email", {
                                required  : true,
                                minLength : 3,
                                pattern   : /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                            })}
                        />
                    </label>
                    <label>
                        <span className="labelText">{t("notes")}</span>
                        <textarea
                            placeholder=""
                            className="notes"
                            {...register("notes", {required : false})}
                        />
                    </label>

                    <div className="form-group">
                        <input
                            type="checkbox"
                            id="checkbox-agree"
                            {...register("agree", {required : true})}
                        />
                        <label
                            htmlFor="checkbox-agree"
                            className={errors?.agree && "red-border-beofre"}
                        >
                <span
                    className={`${errors?.agree && "color-red"} termsFont`}
                    onClick={handleProfileDropdown}
                >
                  <u>{t("conditions")}</u>
                </span>
                        </label>
                    </div>

                    <div className="form-group">
                        <input
                            type="checkbox"
                            id="checkbox-agree2"
                            checked={isIncognito}
                        />
                        <label htmlFor="checkbox-agree2" onClick={toggleIsLoading}>
                            <span className="termsFont">{t("anonymousDonation")}</span>
                        </label>
                    </div>
                    <div className="formSubmitBox">
                        <Button styles={"submitButton"} type={"submit"}>
                            {t("donate")}
                        </Button>
                        <img src={cardsSvg} alt="" name="" height="20px"/>
                    </div>
                </form>
            </div>
            <Modal
                showModal={showModal}
                toggleModal={handleProfileDropdown}
                modalWidth="900px"
                modalHeight="458px"
                modalType="policy"
                title={t("donationTermsConditions")}
            >

                <div className="agreementBox">
                    <span className="headings">{t("generalProvisions")}</span>
                    <span className="subHeadings">{t("generalProvisionsPar1")}</span>
                    <span className="agreementText">{t("generalProvisionsPar2")}</span>
                    <span className="headings">{t("dataStorageCollection")}</span>
                    <span className="agreementText">{t("dataStorageCollectionTitle1")}
                        <ul>
                            <li>{t("nameSurname")}</li>
                            <li>{t("organizationName")}</li>
                            <li>{t("phoneNumber")}</li>
                            <li>{t("eMail")}</li>
                            <li>{t("bankInformation")}</li>
                            <li>{t("etc")}</li>
                        </ul>
                        {t("dataStorageCollectionTitle2")}
                        <ul>
                            <li>{t("dataStorageCollectionLi1")}</li>
                            <li>{t("dataStorageCollectionLi2")}</li>
                            <li>{t("dataStorageCollectionLi3")}</li>
                            <li>{t("dataStorageCollectionLi4")}</li>
                        </ul>
                        {t("dataStorageCollectionPar1")}
                        {t("dataStorageCollectionPar2")}
                        {t("dataStorageCollectionPar3")}
                    </span>
                    <span className="headings">{t("donationIssues")}</span>
                    <span className="agreementText">{t("donationIssuesText")}</span>
                    <span className="headings">{t("websiteLiability")}</span>
                    <span className="agreementText">{t("websiteLiabilityText1")}</span>
                    <span className="agreementText">
                        {t("websiteLiabilityText2")}
                        {t("websiteLiabilityText3")}
                    </span>
                    <span className="headings">{t("cancelationDonations")}</span>
                    <span className="agreementText">{t("cancelationDonationsText")}</span>
                    <span className="headings">{t("lawDisputeResolution")}</span>
                    <span className="agreementText">{t("lawDisputeResolutionPar1")}
                        <br/>
                        <br/>
                        {t("lawDisputeResolutionPar2")}
                        <br/>
                        <br/>
                        {t("lawDisputeResolutionPar3")}
                        <br/>
                        <br/>
                        {t("lawDisputeResolutionPar4")}
                    </span>
                </div>
            </Modal>
            <div className="oneTimeDonation">
                <span className="oneTimeTitle">{t("oneTimeDonation")}</span>
                <span className="oneTimeDesc">
            {t("oneTimeDonationRule1")}
                    <br/>
                    {t("oneTimeDonationRule2")}
          </span>
                <div className="bankIndoBox">
            <span className="bankInfo">
              <b className="bolderText">Recipient Name:</b> "Gituzh" Scientific
              Ecosystem Development Support Foundation
            </span>
                    <span className="bankInfo">
              <b className="bolderText">Bank:</b> Inecobank CJSC
            </span>
                    <span className="bankInfo">
              <b className="bolderText"> SWIFT Code:</b> INJSAM22
            </span>
                    <span className="bankInfo">
              <b className="bolderText">{t("accounts")}:</b>
            </span>
                    <div className="accNumbers">
              <span className="bankNumbers">
                <b>AMD:</b>
                <span>2050422389691001</span>
              </span>
                        <span className="bankNumbers">
                <b>USD:</b>
                <span>
                  2050422389691020
                  <br/>
                  <b>INTERMEDIARY BANK:</b>{" "}
                    BANK OF GEORGIA
                  <br/>
                  <b>SWIFT Code:</b> BAGAGE22
                </span>
              </span>
                        <span className="bankNumbers">
                <b>EUR:</b>
                <span>
                  2050422389691040
                  <br/>
                  <b> INTERMEDIARY BANK:</b>{" "}
                    COMMERZBANK AG.
                  <br/>
                  <b> SWIFT Code: </b> COBADEFF
                </span>
              </span>
                        <span className="bankNumbers">
                <b>RUB:</b>
                <span>
                  2050422389691040
                  <br/>
                  <b> INTERMEDIARY BANK: </b>{" "}
                    Евразийский банк развития
                  <br/>
                  <b> БИК: </b> 044525815
                </span>
              </span>
                    </div>
                </div>
            </div>
        </div>

        <Modal
            showModal={showResponseModal}
            toggleModal={() => setShowResponseModal(false)}
            modalWidth="600px"
            modalHeight={code === "00" ? "450px" : Boolean(modalMsgContent) ? "400px" : "366px"}
            modalType={code === "00" ? "owl" : i18n.language === "en" ? "failedEN" : "failedAM"}
            title={code === "00" ? t("owlSuccess") : ""}
            message={modalMsgContent}
        >
        </Modal>
    </div>);
};

export default Donation;
