import React from "react";
import facebook from '../../assets/icons/social/facebook.svg';
import instagram from '../../assets/icons/social/instagram.svg';
import twitter from '../../assets/icons/social/twitter.svg';
import linkedin from '../../assets/icons/social/linkedin.svg';
import {useTranslation} from 'react-i18next';
import './footer.scss';

const Footer = () => {
    const {t} = useTranslation();


    return (
        <>
            <div className="footer">
                <div className="footerInfo">
                    <span className="email"><b>{t('email2')}</b> <a href='mailto: info@gituzh.am'> info@gituzh.am</a></span>
                    <span className="phone"><b>{t('phoneNumer')}</b> <a href='tel: +374 94 87 83 00'> +374 94 87 83 00</a></span>
                    <span className="phone"><b></b> Powered by <a href='https://concavesystems.com'> Concave Systems</a></span>
                </div>
                <div className="footerDesc">
                    <span className="footerText">
                        {t('followSocialMedia')}
                    </span>
                    <div className="social">
                        <a href="https://www.facebook.com/gituzh.am/"><img src={facebook} alt='facebook' name='facebook'/></a>
                        <a href="https://www.linkedin.com/company/gituzh/"><img src={linkedin} alt='linkedin' name='linkedin'/></a>
                        <a href="https://www.instagram.com/gituzh.am/"><img src={twitter} alt='twitter' name='twitter'/></a>
                        <a href="https://twitter.com/gituzh"><img src={instagram} alt='instagram' name='instagram'/></a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer;